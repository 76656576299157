<template>
    <div class="content">
        <div class="remoteDiagnosis">
            <div class="remoteDiagnosisImg"></div>
            <div class="remoteDiagnosisInfo">
                <h3 class="remoteDiagnosisTitle">远程影像诊断</h3>
                <p class="remoteDiagnosisDesc">医疗影像诊断是医学诊断的重要组成部分，而医学影像诊断对医师有较高的要求，基层医院医生一般不具备相关的能力，导致基层医疗机构有影像设备，但缺乏有能力阅片的医生，大量影像阅片需求无法满足。系统不仅支持院内远程阅片服务，同时还支持医联体内或其他区域的远程影像诊断服务。系统自带影像阅片软件，支持多系统多终端，无软硬件要求。下级医院拍片后，把影像数据托管至云平台，由上级医院为下级医院提供阅片服务，从而实现医疗影像的跨院、跨区域互通与共享，让大医院的优势医疗资源得到下沉，让下级医院的患者可以得到上级医生的即时诊断服务，解决医疗资源分配不均的问题。</p>
            </div>
        </div>
        <!-- <div class="container dicomCenterContainer">
            <h3 class="dicomCenterTitle">区域影像中心</h3>
            <p class="dicomCenterDesc">均衡医疗资源、提高基层医院诊疗水平、提高影像设备利用率、提升医疗 服务质量和效率。</p>
            <div class="dicomCenterImg"></div>
        </div> -->
        <div class="container remoteConsultationContainer">
            <div class="remoteConsultationInfo">
                <h3 class="remoteConsultationTitle">远程影像会诊</h3>
                <p class="remoteConsultationDesc">医远程影像会诊是以计算机和网络通信为基础，综合运用医疗设备、医学资料多媒体技术，进行远距离图像采集、存储、传输、分析和处理，将患者图像和数据和音视频远距离传输、存储、查询和显示，实现专家、患者与医务人员之间远距离“面对面”的会诊治疗过程。</p>
            </div>
        </div>
        <div class="remoteConsultationMeaning">
            <h3 class="remoteConsultationMeaningTitle">远程影像会诊的意义</h3>
            <p class="remoteConsultationMeaningDesc">远程影像会诊是目前应用最广泛的远程医疗形式，已广泛应用于全院或医联体内的联合会诊服务。系统提供移动远程影像会诊系统，支持通过手机电脑等各种终端设备完成影像会诊，支持医联体会诊、多院区会诊、MDT多学科会诊服务。</p>
            <div class="remoteConsultationMeaningCards">
                <div class="remoteConsultationMeaningCard">
                    <el-image class="remoteConsultationMeaningCardImg" fit="cover" :src="require('@/assets/images/web/product/kuaquyu.png')"></el-image>
                    <h3 class="remoteConsultationMeaningCardTitle">跨区域医疗协同及远程专家会诊</h3>
                </div>
                 <div class="remoteConsultationMeaningCard">
                    <el-image class="remoteConsultationMeaningCardImg" fit="cover" :src="require('@/assets/images/web/product/tigao.png')"></el-image>
                    <h3 class="remoteConsultationMeaningCardTitle">提高基层医务人员的诊断水平</h3>
                 </div>
            </div>
        </div>
        <div class="container cloudDicomBuildContainer">
            <div class="cloudDicomBuild">
                <h3 class="cloudDicomBuildTitle">影像云建设</h3>
                <h4 class="cloudDicomBuildSubtitle">连同医联体内各级别医疗机构，实现影像数据的互联互通</h4>
                <el-image class="cloudDicomBuildImg" fit="cover" :src="require('@/assets/images/web/product/jianshe.png')"></el-image>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {}
    }
}
</script>

<style lang="less" scoped>
.content{
    .container{
        width: 100%;
        display: inline-block;
    }
    .remoteDiagnosis{
        width: 1200px;
        height: 505px;
        margin: 0 auto;
        margin-top: 98px;
        display: flex;
        flex-direction: row;
        .remoteDiagnosisImg{
            height: 505px;
            width: 415px;
            background: url('../../../../assets/images/web/product/yuanchyeng.png') no-repeat;
            background-size: 100% 100%;
        }
        .remoteDiagnosisInfo{
            margin-left: 104px;
            width: 656px;
            height: 505px;
            .remoteDiagnosisTitle{
                font-size: 45px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #29262A;
                margin:0 !important;
            }
            .remoteDiagnosisDesc{
                margin-top: 45px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #606062;
                line-height: 41px;
                text-align: justify;
            }
        }
    }
    .dicomCenterContainer{
        background: url('../../../../assets/images/web/product/yixiangzhongxin@2x.png') no-repeat;
        background-size: 100% 100%;
        height: 814px;
        margin-top: 74px;
        .dicomCenterTitle{
            text-align: center;
            margin-top: 100px;
            font-size: 52px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #BCEAFF;
        }
        .dicomCenterDesc{
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(188, 234, 255, 0.59);
            margin-top: 58px;
            text-align: center;
        }
        .dicomCenterImg{
            width: 1175px;
            height: 400px;
            background: url('../../../../assets/images/web/product/quyu.png') no-repeat;
            background-size: 100% 100%;
            margin: 0 auto;
            margin-top: 80px;
        }
    }
    .remoteConsultationContainer{
        margin-top: 65px;
        height: 604px;
        background: url('../../../../assets/images/web/product/ylxt_ycyxhz_.png') no-repeat;
        background-size: 100% 100%;
        .remoteConsultationInfo{
            width: 1175px;
            height: 604px;
            margin: 0 auto;
            .remoteConsultationTitle{
                font-size: 45px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                margin-top: 106px;
            }
            .remoteConsultationDesc{
                margin-top: 48px;
                width: 467px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 41px;
            }
        }
    }
    .remoteConsultationMeaning{
        width: 1200px;
        height: 801px;
        margin: 0 auto;
        margin-top: 123px;
        margin-bottom: 122px;
        .remoteConsultationMeaningTitle{
            font-size: 45px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #29262A;
            text-align: center;
        }
        .remoteConsultationMeaningDesc{
            width: 1200px;
            font-size: 22px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #606062;
            line-height: 40px;
            text-align: center;
        }
        .remoteConsultationMeaningCards{
            width: 1200px;
            height: 545px;
            margin-top: 108px;
            display: flex;
            flex-direction: row;
            .remoteConsultationMeaningCard{
                &:last-child{
                    margin-right: 0px;
                }
                margin-right: 27px;
                width: 601px;
                height: 545px;
                background: #FFFFFF;
                box-shadow: 0px 7px 24px 0px rgba(12, 41, 72, 0.16);
                .remoteConsultationMeaningCardImg{
                    width: 552px;
                    height: 385px;
                    margin: 0 auto;
                    margin-top: 28px;
                    display: block;
                }
                .remoteConsultationMeaningCardTitle{
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #3C3C3C;
                    text-align: center;
                    margin-top: 56px;
                }
            }
        }
    }
    .cloudDicomBuildContainer{
        background: url('../../../../assets/images/web/product/yinxiangyunjiansje@2x.png') no-repeat;
        background-size: 100% 100%;
        height: 1300px;
        .cloudDicomBuild{
            width: 1200px;
            margin: 0 auto;
            margin-top: 90px;
            .cloudDicomBuildTitle{
                font-size: 45px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #BCEAFF;
                text-align: center;
                margin-top: 90px;
            }
            .cloudDicomBuildSubtitle{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #85ABBF;
                text-align: center;
                margin-top: 46px;
            }
            .cloudDicomBuildImg{
                display: block;
                width: 1064px;
                height: 878px;
                margin: 0 auto;
                margin-top: 116px;
            }
        }
    }
}
</style>
